/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */

import { AxiosError } from 'axios';
import { BASE_URL } from './constants';

export function range(start: number, end: number): number[] {
  let firstNumber = start;
  return Array.from({ length: end - start + 1 }).map(_ => firstNumber++);
}

export function isEmpty(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (value.constructor === Array && value.length === 0)
  );
}

export function isAxiosError(err: Error | AxiosError): err is AxiosError {
  return 'response' in err;
}

export function formatDate(date: Date, showTime?: boolean) {
  return date.toLocaleString('en-GB', {
    day: 'numeric', // numeric, 2-digit
    year: 'numeric', // numeric, 2-digit
    month: 'numeric', // numeric, 2-digit, long, short, narrow
    ...(!!showTime && { hour: '2-digit', minute: '2-digit' }),
  });
}

export function downloadCSV(filePath: string) {
  const link = document.createElement('a');
  link.href = `${BASE_URL}${filePath}`;
  link.download = `GetMeAChai_${Date.now()}.csv`;
  link.click();
}

export function debounce(cb: (...args: any[]) => void, delay = 1000) {
  let timeout: any;

  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

export async function copyTextToClipboard(text: string) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'absolute';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
  }
}
