import { AxiosRequestConfig } from 'axios';
import http from './httpService';
import { BASE_URL } from '../helpers/constants';
import { isAxiosError } from '../helpers/utilities';
import {
  ChangePassword,
  ExportResponse,
  Filters,
  FilterParams,
  SuccessMessage,
} from '../types/common';
import {
  ArtistRequestsList,
  PersonalInfoType,
  PublicProfileType,
  UsersList,
  UserStatusBody,
  UserType,
  VerifiableInfoType,
  UpdatePublicProfileData,
} from '../types/models/user';

export async function updatePersonalInfo(userData: PersonalInfoType) {
  const formData = new FormData();

  Object.entries(userData).forEach(([key, value]) => {
    if (key === 'profilePicture') {
      if (value && typeof value !== 'string') {
        formData.append(key, value);
      }
    } else if (value) {
      formData.append(key, value);
    }
  });

  const { data } = await http.put<SuccessMessage>('/user/update_user', formData);

  return data;
}

export async function updateVerifiableInfo(userData: VerifiableInfoType) {
  const formData = new FormData();

  Object.entries(userData).forEach(([key, value]) => {
    if (key === 'cnicFront' || key === 'cnicBack') {
      if (value && typeof value !== 'string') {
        formData.append(key, value);
      }
    } else if (key === 'category') {
      if (value !== 'other') formData.append(key, value);
    } else if (value) {
      formData.append(key, value);
    }
  });

  const { data } = await http.put<SuccessMessage>('/user/update_user', formData);

  return data;
}

export async function updatePublicProfile(userData: UpdatePublicProfileData) {
  const formData = new FormData();

  Object.entries(userData).forEach(([key, value]) => {
    if (key === 'profilePicture' || key === 'featuredImage') {
      if (value && typeof value !== 'string' && typeof value !== 'boolean') {
        formData.append(key, value);
      }
    } else if (typeof value === 'string') {
      formData.append(key, value);
    }
  });

  const { data } = await http.put<SuccessMessage>('/user/update_user', formData);

  return data;
}

export async function changeCoverPhoto(img: PublicProfileType['userDetails']['coverImage']) {
  const formData = new FormData();

  if (img) {
    formData.append('coverImage', img);
  }

  const { data } = await http.put<SuccessMessage>('/user/update_user', formData);

  return data;
}

export async function getLoggedinUserDetail() {
  try {
    const { data } = await http.get<UserType>('/user/me');

    const responseData: UserType = {
      ...data,
      profilePicture: data.profilePicture ? `${BASE_URL}/images/${data.profilePicture}` : '',
      cnicFront: data.cnicFront ? `${BASE_URL}/images/${data.cnicFront}` : '',
      cnicBack: data.cnicBack ? `${BASE_URL}/images/${data.cnicBack}` : '',
    };

    return responseData;
  } catch (err: any) {
    if (isAxiosError(err)) throw err.response?.data;
    throw err;
  }
}

export async function getPublicProfileDetail(
  username: string | [string, string],
  config?: AxiosRequestConfig<any>
) {
  try {
    const param = Array.isArray(username) ? username[1] : username;

    const { data } = await http.get<PublicProfileType>(`/user/details/${param}`, config);

    if (!data) return data;

    const responseData: PublicProfileType = {
      userDetails: {
        ...data.userDetails,
        profilePicture: data.userDetails.profilePicture
          ? `${BASE_URL}/images/${data.userDetails.profilePicture}`
          : '',
        featuredImage: data.userDetails.featuredImage
          ? `${BASE_URL}/images/${data.userDetails.featuredImage}`
          : '',
        coverImage: data.userDetails.coverImage
          ? `${BASE_URL}/images/${data.userDetails.coverImage}`
          : '',
      },
      supporters: data.supporters,
    };

    return responseData;
  } catch (err: any) {
    if (isAxiosError(err)) throw err.response?.data;
    throw err;
  }
}

export async function getArtistRequests(params: FilterParams) {
  const filters = params[1];
  try {
    const { data } = await http.get<ArtistRequestsList>('/admin/get_users', {
      params: filters,
    });
    return data;
  } catch (err: any) {
    if (isAxiosError(err)) throw err.response?.data;
    throw err;
  }
}

export async function getUsersList(params: FilterParams) {
  const filters = params[1];
  const queryParams = {
    page: filters.page,
    search: filters.search,
    userStatus: filters.status,
    sortOrder: filters.sortOrder,
    sortBy: filters.sortBy,
    status: 'Approved',
  };

  try {
    const { data } = await http.get<UsersList>('/admin/get_users', {
      params: queryParams,
    });
    return data;
  } catch (err: any) {
    if (isAxiosError(err)) throw err.response?.data;
    throw err;
  }
}

export async function getUserDetailsById(params: [string, string]) {
  const userId = params[1];

  try {
    const { data } = await http.get<UserType>(`/admin/get_user_details/${userId}`);

    const responseData: UserType = {
      ...data,
      profilePicture: data.profilePicture ? `${BASE_URL}/images/${data.profilePicture}` : '',
      cnicFront: data.cnicFront ? `${BASE_URL}/images/${data.cnicFront}` : '',
      cnicBack: data.cnicBack ? `${BASE_URL}/images/${data.cnicBack}` : '',
    };

    return responseData;
  } catch (err: any) {
    if (isAxiosError(err)) throw err.response?.data;
    else throw err;
  }
}

export async function changeArtistRequestStatus(payload: UserStatusBody) {
  const { data } = await http.put<SuccessMessage>('/admin/update_user_status', payload);
  return data;
}

export async function changeUserPermission(userId: string) {
  const { data } = await http.put<SuccessMessage>('/admin/update_user_permission', {
    _id: userId,
  });
  return data;
}

export async function exportUsers(filters: Filters) {
  const queryParams = {
    page: filters.page,
    search: filters.search,
    userStatus: filters.status,
    sortOrder: filters.sortOrder,
    sortBy: filters.sortBy,
  };

  try {
    const { data } = await http.get<ExportResponse>('/admin/export_users', {
      params: queryParams,
    });
    return data;
  } catch (err: any) {
    if (isAxiosError(err)) throw err.response?.data;
    throw err;
  }
}

export async function changeUserPassword(payload: ChangePassword) {
  const { data } = await http.put<SuccessMessage>('/user/change_password', payload);
  return data;
}

export async function disableUserAccount() {
  const { data } = await http.get<SuccessMessage>('/user/disable_account');
  return data;
}

export async function updateUsername(payload: Pick<UserType, 'username'>) {
  const { data } = await http.put<SuccessMessage>('/user/update_user', payload);
  return data;
}

getLoggedinUserDetail.key = 'getLoggedinUserDetail';
getPublicProfileDetail.key = 'getPublicProfileDetail';
getArtistRequests.key = 'getArtistRequests';
getUserDetailsById.key = 'getUserDetailsById';
getUsersList.key = 'getUsersList';
